@import './config';

@import './general/base';
@import './general/animations';
@import './general/utils';

@import './loaders/loader';
@import './loaders/loader-double';
@import './loaders/loader-bar';
@import './loaders/loader-border';
@import './loaders/loader-ball';
@import './loaders/loader-smartphone';
@import './loaders/loader-clock';
