// Data text
// ==========================================================================
.loader {

  &[data-text] {

    &:before {
      position: fixed;
      left: 0;
      top: 50%;
      color: currentColor;
      font-family: $font-loader;
      text-align: center;
      width: 100%;
      font-size: $font-size-loader; } } }

// Default text
//&[data-text = '']
//  &:before
//    content: 'Loading'

// Passing text as attribute
//&:not([data-text = ''])
//  &:before
//    content: attr(data-text)

// Blink animation
//&[blink]
//  &:before
//    animation: blink 1s linear infinite alternate
