// Loader double
// ==========================================================================

// Config
$loader-double--size-external: $loader-double--size + ($loader-double--border * 2);

.loader-double {

  &:after,
  &:before {
    content: '';
    position: fixed;
    border-radius: 50%;
    border: solid $loader-double--border;
    animation: rotation 1s linear infinite; }

  &:after {
    width: $loader-double--size;
    height: $loader-double--size;
    border-color: $loader-double--color;
    border-left-color: transparent;
    top: calc(50% - #{$loader-double--size / 2});
    left: calc(50% - #{$loader-double--size / 2}); }

  &:before {
    width: $loader-double--size-external;
    height: $loader-double--size-external;
    border-color: $loader-double--color-external;
    border-right-color: transparent;
    animation-duration: 2s;
    top: calc(50% - #{($loader-double--size / 2) + $loader-double--border});
    left: calc(50% - #{($loader-double--size / 2) + $loader-double--border}); } }
