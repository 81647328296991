// Loader bar
// ==========================================================================

.loader-bar {

  // Before is used for data-text attribute
  &[data-text]:before {
    top: calc(50% - 40px);
    color: $loader-bar--text-color; }

  &:after {
    content: '';
    position: fixed;
    top: 50%;
    left: 50%;
    width: 200px;
    height: 20px;
    transform: translate(-50%, -50%);
    background: linear-gradient( -45deg, $loader-bar--color-secondary 25%, $loader-bar--color 25%, $loader-bar--color 50%, $loader-bar--color-secondary 50%, $loader-bar--color-secondary 75%, $loader-bar--color 75%, $loader-bar--color);
    background-size: 20px 20px;
    box-shadow: inset 0 10px 0 rgba(255, 255, 255, .2), 0 0 0 5px rgba(0, 0, 0, .2);
    animation: moveBar 1.5s linear infinite; }

  // Rounded version
  &[rounded] {
    &:after {
      border-radius: 15px; } } }

// Animation for bars
@keyframes moveBar {
  from {
    background-position: 0 0; }

  to {
    background-position: 20px 20px; } }
