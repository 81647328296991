// Base
// ==========================================================================
$bg-loader: rgba(0, 0, 0, .85);
$font-loader: Helvetica, Arial, sans-serif;
$font-size-loader: 14px;

// Loader default
// ==========================================================================
$loader--size: 48px;
$loader--color: #fff;
$loader--text-color: #fff;

// Loader double
// ==========================================================================
$loader-double--color: #fff;
$loader-double--color-external: #eb974e;
$loader-double--size: 48px;
$loader-double--border: 8px;

// Loader bar
// ==========================================================================
$loader-bar--color: #52b3d9;
$loader-bar--color-secondary: #4183d7;
$loader-bar--text-color: #fff;

// Loader border
// ==========================================================================
$loader-border--width: 15px;
$loader-border--height: 15px;
$loader-border--text-color: #fff;
$loader-border--color: #fff000;

// Loader ball
// ==========================================================================
$loader-ball--color: #fff;
$loader-ball--shadow: rgba(0, 0, 0, .5);

// Loader smartphone
// ==========================================================================
$loader-smartphone--color: #ffd700;
$loader-smartphone--text-color: #fff;

// Loader clock
// ==========================================================================
$loader-clock--color: #2ecc71;
$loader-clock--color-bg: #f5f5f5;
$loader-clock--color-border: #555;
