// Loader (default)
// ==========================================================================

.loader-default {

  // Before is used for data-text attribute
  &[data-text]:before {
    top: calc(50% - #{($loader--size + 15)}); }

  &:after {
    content: '';
    position: fixed;
    width: $loader--size;
    height: $loader--size;
    border: solid 8px $loader--color;
    border-left-color: transparent;
    border-radius: 50%;
    top: calc(50% - #{$loader--size / 2});
    left: calc(50% - #{$loader--size / 2});
    animation: rotation 1s linear infinite; }

  // Half
  &[half] {
    &:after {
      border-right-color: transparent; } } }

// Inverse
//&[inverse]
//  &:after
//    animation-direction: reverse
