// Loader ball
// ==========================================================================
.loader-ball {

  &:before {
    content: '';
    position: absolute;
    width: 50px;
    height: 50px;
    top: 50%;
    left: 50%;
    margin: -25px 0 0 -25px;
    background-color: $loader-ball--color;
    border-radius: 50%;
    z-index: 1;
    animation: kick 1s infinite alternate ease-in both; }

  &[shadow]:before {
    box-shadow: -5px -5px 10px 0 $loader-ball--shadow inset; }

  &:after {
    content: '';
    position: absolute;
    background-color: rgba(0, 0, 0, .3);
    border-radius: 50%;
    width: 45px;
    height: 20px;
    top: calc(50% + 10px);
    left: 50%;
    margin: 0 0 0 -22.5px;
    z-index: 0;
    animation: shadow 1s infinite alternate ease-out both; } }

@keyframes shadow {
  0% {
    background-color: rgba(0, 0, 0, 0);
    transform: scale(0); }

  40% {
    background-color: rgba(0, 0, 0, 0);
    transform: scale(0); }

  95% {
    background-color: rgba(0, 0, 0, .75);
    transform: scale(1); }

  100% {
    background-color: rgba(0, 0, 0, .75);
    transform: scale(1); } }

@keyframes kick {
  0% {
    transform: translateY(-80px) scaleX(.95); }

  90% {
    border-radius: 50%; }

  100% {
    transform: translateY(0) scaleX(1);
    border-radius: 50% 50% 20% 20%; } }
