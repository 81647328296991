.loader {
  color: $loader--text-color;
  position: fixed;
  box-sizing: border-box;
  left: -9999px;
  top: -9999px;
  width: 0;
  height: 0;
  overflow: hidden;
  z-index: 999999;

  &:after,
  &:before {
    box-sizing: border-box; }

  &.is-active {
    background-color: $bg-loader;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0; } }
