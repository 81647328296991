// Loader smartphone
// ==========================================================================

.loader-smartphone {

  &:after {
    content: '';
    color: $loader-smartphone--text-color;
    font-size: 12px;
    font-family: $font-loader;
    text-align: center;
    line-height: 120px;
    position: fixed;
    left: 50%;
    top: 50%;
    width: 70px;
    height: 130px;
    margin: -65px 0 0 -35px;
    border: solid 5px $loader-smartphone--color;
    border-radius: 10px;
    box-shadow: 0 5px 0 0 $loader-smartphone--color inset;
    background: radial-gradient(circle at 50% 90%, rgba(0, 0, 0, .5) 6px, transparent 6px), linear-gradient(to top, $loader-smartphone--color 22px, transparent 22px), linear-gradient(to top, rgba(0, 0, 0, .5) 22px, rgba(0, 0, 0, .5) 100%);
    animation: shake 2s cubic-bezier(.36, .07, .19, .97) both infinite; }

  &[data-screen = ''] {
    &:after {
      content: 'Loading'; } } }

// Passing text as attribute
//&:not([data-screen = ''])
//  &:after
//    content: attr(data-screen)

@keyframes shake {
  5% {
    transform: translate3d(-1px, 0, 0); }

  10% {
    transform: translate3d(1px, 0, 0); }

  15% {
    transform: translate3d(-1px, 0, 0); }

  20% {
    transform: translate3d(1px, 0, 0); }

  25% {
    transform: translate3d(-1px, 0, 0); }

  30% {
    transform: translate3d(1px, 0, 0); }

  35% {
    transform: translate3d(-1px, 0, 0); }

  40% {
    transform: translate3d(1px, 0, 0); }

  45% {
    transform: translate3d(-1px, 0, 0); }

  50% {
    transform: translate3d(1px, 0, 0); }

  55% {
    transform: translate3d(-1px, 0, 0); } }
