// General animations
// ==========================================================================

@keyframes rotation {
  from {
    transform: rotate(0); }

  to {
    transform: rotate(359deg); } }

@keyframes blink {
  from {
    opacity: .5; }

  to {
    opacity: 1; } }
